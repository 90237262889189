<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar
      v-bind:secondTierLoadingDone="true"/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card class = "mt-5">
            <div v-html="addInPrivacyPolicy" class="mt-1"></div>
          </b-card>
        </b-col>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
      </b-row>
    </b-container>
    </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from '../components/TopNavBar'
import GenderAppFooter from '../components/Footer'
import { SiteManagerService } from '../services/siteManagerService'

export default {
  name: 'addInPrivacyPolicy',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: 'Datenschutzerklärung · Add-in gender app',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: 'Das Kleingedruckte des add-ins gender app'
        },
        { name: 'robots', vmid: 'robots', content: 'noindex,follow' }
      ]
    }
  },
  components: {
    GenderAppFooter,
    TopNavBar
  },
  data () {
    return {
      addInPrivacyPolicy: ''
    }
  },
  methods: {
    getSiteManager () {
      SiteManagerService.getListViewData('', 'all')
        .then((response) => {
          // console.log(response.data.results[0].addInPrivacyPolicy)
          this.addInPrivacyPolicy = response.data.results[0].addInPrivacyPolicy
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getSiteManager()
  }
}
</script>
